import { FormControl, TextField, Button, Typography } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import React from 'react';

const FormCheckout = () => {

    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);

    const [nome, setNome] = React.useState("");
    const [endereco, setEndereco] = React.useState("");
    const [numero, setNumero] = React.useState("");
    const [telefone, setTelefone] = React.useState("");
    const [mensagem, setMensagem] = React.useState("");

    const handleChange = (event) => {
      switch (event.target.id) {
        case "nomeTextCheckout": 
          setNome(event.target.value);
          break;
        case "enderecoTextCheckout": 
          setEndereco(event.target.value);
          break;
        case "numeroTextCheckout": 
          setNumero(event.target.value);
          break;
        case "telefoneTextCheckout": 
          setTelefone(event.target.value);
          break;
        case "mensagemTextCheckout": 
          setMensagem(event.target.value);
          break;
        default: 
          break;
      }
    };

    const handleButtonClick = (event) => {
      
    }

    return (
      <div className="formCheckout">
        <FormControl style={{zIndex: '0'}}>
          <div className="textFormCheckout">
            <Typography
              style={{ textAlign: "center", fontWeight: "bold" }}
              variant="h6"
              component="div"
            >
              Dados pessoais
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              variant="body"
              component="div"
            >
              Entraremos em contato com você para finalizar o atendimento!
            </Typography>
          </div>
          <div className="inputFormCheckout">
            <TextField
              sx={{ width: "45ch" }}
              helperText=" "
              id="nomeTextCheckout"
              label="Nome"
              onChange={handleChange}
            />
            <div className="enderecoCheckout">
              <TextField
                sx={{ width: "30ch" }}
                helperText=" "
                id="enderecoTextCheckout"
                label="Endereço"
                onChange={handleChange}
              />
              <TextField
                sx={{ width: "15ch", margin: "0 2px" }}
                helperText=" "
                id="numeroTextCheckout"
                label="Número"
                type='number'
                onChange={handleChange}
              />
            </div>
            <TextField
              sx={{ width: "45ch", margin: "0 2px" }}
              helperText=" "
              id="telefoneTextCheckout"
              label="Telefone"
              onChange={handleChange}
            />
            <TextField
              sx={{ width: "45ch" }}
              helperText=" "
              id="telefoneTextCheckout"
              label="Mensagem"
              multiline
              onChange={handleChange}
            />
            <Button variant="contained" sx={{ padding: "15px 20px" }} onClick={handleButtonClick} >
              Solicitar Atendimento
            </Button>
          </div>
        </FormControl>
      </div>
    );
}
 
export default FormCheckout;