let smart = {}
smart.abertos = [
    { nome: "Agro Brasil", categoria: "Agrícolas" },
    { nome: "Agro Canal", categoria: "Abertos" },
    { nome: "Band", categoria: "Abertos" },
    { nome: "Canal Saúde", categoria: "Abertos" },
    { nome: "Canal do Boi", categoria: "Agrícolas" },
    { nome: "Canção Nova", categoria: "Religiosos" },
    { nome: "Empresários de Sucesso TV", categoria: "Variedades" },
    { nome: "Futura", categoria: "Abertos" },
    { nome: "Ideal TV", categoria: "Religiosos" },
    { nome: "Markket", categoria: "Variedades" },
    { nome: "Mais Família", categoria: "Abertos" },
    { nome: "Polishop", categoria: "Abertos" },
    { nome: "Record TV", categoria: "Abertos" },
    { nome: "Rede TV", categoria: "Abertos" },
    { nome: "Rede Super", categoria: "Abertos" },
    { nome: "Rede 21", categoria: "Abertos" },
    { nome: "Rede Vida", categoria: "Religiosos" },
    { nome: "RIT TV", categoria: "Abertos" },
    { nome: "Rede CNT", categoria: "Religiosos" },
    { nome: "Record News", categoria: "Abertos" },
    { nome: "RBTV", categoria: "Abertos" },
    { nome: "Rede Família", categoria: "Abertos" },
    { nome: "Rede Sempre", categoria: "Abertos" },
    { nome: "Rede Metrópole", categoria: "Abertos" },
    { nome: "SBT", categoria: "Abertos" },
    { nome: "SescTV", categoria: "Variedades" },
    { nome: "Terraviva", categoria: "Agrícolas" },
    { nome: "TV Brasil", categoria: "Abertos" },
    { nome: "TV Escola", categoria: "Abertos" },
    { nome: "TV Aparecida", categoria: "Religiosos" },
    { nome: "TV Senado", categoria: "Abertos" },
    { nome: "TV Câmara", categoria: "Abertos" },
    { nome: "TV Justiça", categoria: "Abertos" },
    { nome: "TV Climatempo", categoria: "Variedades" },
    { nome: "TV Pai Eterno", categoria: "Abertos" },
    { nome: "TV Diário", categoria: "Abertos" },
    { nome: "TV Gazeta", categoria: "Abertos" },
    { nome: "TV Evangelizar", categoria: "Religiosos" },
    { nome: "Top TV", categoria: "Abertos" },
];


smart.mini = smart.abertos.concat([
    { nome: "A&E", categoria: "Culturais" },
    { nome: "Agro Mais", categoria: "Agrícolas" },
    { nome: "Band News", categoria: "Notícias" },
    { nome: "Canal Empreender", categoria: "Variedades" },
    { nome: "Cartoon Network", categoria: "Infantis" },
    { nome: "Cine Brasil TV", categoria: "Filmes e Séries" },
    { nome: "CineCanal", categoria: "Filmes e Séries" },
    { nome: "CNN Brasil", categoria: "Notícias" },
    { nome: "Discovery Channel", categoria: "Culturais" },
    { nome: "Discovery H&H", categoria: "Culturais" },
    { nome: "Discovery Kids", categoria: "Infantis" },
    { nome: "Disney Channel", categoria: "Infantis" },
    { nome: "E!", categoria: "Filmes e Séries" },
    { nome: "ESPN", categoria: "Esportivos" },
    { nome: "Fish TV", categoria: "Variedades" },
    { nome: "History", categoria: "Culturais" },
    { nome: "Jovem Pan News", categoria: "Notícias" },
    { nome: "Love Nature", categoria: "Culturais" },
    { nome: "National Geographic", categoria: "Culturais" },
    { nome: "Prime Box Brazil", categoria: "Varieadades" },
    { nome: "Sony Channel", categoria: "Filmes e Séries" },
    { nome: "Star Channel", categoria: "Filmes e Séries" },
    { nome: "TNT", categoria: "Filmes e Séries" },
    { nome: "TV WA", categoria: "Variedades" },
    { nome: "Warner TV", categoria: "Filmes e Séries" },
    { nome: "ZooMoo Kids", categoria: "Infantis" },
]);


smart.plus = smart.mini.concat([
    { nome: "Arte 1", categoria: "Culturais" },
    { nome: "AMC", categoria: "Filmes e Séries" },
    { nome: "Animal Planet", categoria: "Culturais" },
    { nome: "AXN", categoria: "Filmes e Séries" },
    { nome: "Baby TV,", categoria: "Infantis" },
    { nome: "Band Sports", categoria: "Esportivos" },
    { nome: "Cartoonito", categoria: "Infantis" },
    { nome: "Discovery Turbo", categoria: "Culturais" },
    { nome: "ESPN 2", categoria: "Esportivos" },
    { nome: "ESPN 4", categoria: "Esportivos" },
    { nome: "Fashion TV", categoria: "Variedades" },
    { nome: "FX", categoria: "Filmes e Séries" },
    { nome: "Fox Sports 2", categoria: "Esportivos" },
    { nome: "ID Investigação Discovery", categoria: "Culturais" },
    { nome: "Music Box Brazil", categoria: "Variedades" },
    { nome: "Space", categoria: "Filmes e Séries" },
    { nome: "Sabor & Arte", categoria: "Variedades" },
    { nome: "TruTV", categoria: "Variedades" },
    { nome: "Travel Box Brazil", categoria: "Variedades" },
    { nome: "TBS", categoria: "Filmes e Séries" },
    { nome: "TCM", categoria: "Filmes e Séries" },
    { nome: "TLC", categoria: "Culturais" },
    { nome: "TNT Séries", categoria: "Filmes e Séries" },
    { nome: "TV Rá Tim Bum", categoria: "Infantis" },
    { nome: "WooHoo", categoria: "Variedades" },
]);


smart.total = smart.plus.concat([
    { nome: "Al Jazeera", categoria: "Culturais" },
    { nome: "Arirang", categoria: "Culturais" },
    { nome: "Chef TV", categoria: "Variedades" },
    { nome: "CNN International", categoria: "Notícias" },
    { nome: "CNN Espanhol", categoria: "Notícias" },
    { nome: "Discovery Science", categoria: "Culturais" },
    { nome: "Discovery Theater", categoria: "Culturais" },
    { nome: "Discovery World", categoria: "Culturais" },
    { nome: "ESPN 3", categoria: "Esportivos" },
    { nome: "ESPN Extra", categoria: "Esportivos" },
    { nome: "Film & Arts", categoria: "Variedades" },
    { nome: "HGTV", categoria: "Variedades" },
    { nome: "History 2", categoria: "Culturais" },
    { nome: "NHK", categoria: "Culturais" },
    { nome: "Lifetime", categoria: "Variedades" },
    { nome: "Tooncast", categoria: "Infantis" },
]);

export default smart 