import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/GlobalContext";


import FABWhatsapp from "../../components/FABWhatsapp";
import Header from "../../components/Header";
import Caroussel from "../../components/Carroussel";
import ServicosImgs from "../../components/ServicosImgs"
import Planos from "../../components/Planos";
import AreaDoCliente from "../../components/AreaDoCliente";
import Formulario from "../../components/Formulario";
import Footer from "../../components/Footer";

// Componentes excluídos
//import Servicos from "../Servicos";
//import MontagemPlano from "../MontagemPlano";
//import BarraInferiorPersistente from '../BarraInferiorPersistente';

import "./index.css";
import PromoITTV from "../../components/PromoITTV";
import RateSnackbar from "../../components/RateSnackbar";




const PaginaInicial = (props) => {
  const navigate = useNavigate();
  const { city, changeCity } = useGlobalContext();

  changeCity(props.city);

  React.useEffect(() => {
    if (city === null) {
      navigate("/");
    }
  }, [city]); 

  if (!city) {
    return <></>;
  }

  return (
    <div className="fundoInicio">
      <Caroussel />
      {/*<ServicosImgs/>*/}
      <Planos />
      <PromoITTV />
      <Formulario />
      <Footer />
      {/*Elementos flutuantes*/}
      <Header offset={100} />
       <RateSnackbar/>
       {/*<FABWhatsapp/>*/}
    </div>
  );
};

export default PaginaInicial;
