import React from "react";
import { cidades } from "../../Utils/Cidades";
import { useNavigate } from "react-router-dom";
import "./CitySearch.css";
import { useGlobalContext } from '../../context/GlobalContext';

const CitySearch = () => {
  const navigate = useNavigate();
  const element = [];

  const { changeCity } = useGlobalContext();

  Object.keys(cidades).forEach((data) => {
    element.push(
      <div
        onClick={(event) => handleCidadeClick(event.target.innerText)}
        className="cidadeBtn"
        key={cidades[data].value}
      >
        {cidades[data].value}
      </div>
    );
  });

  const handleCidadeClick = (search) => {
    let citySelected;
    for (let city in cidades) {
      if (cidades[city].value === search) {
        citySelected = cidades[city];
      }
    }
    console.log(citySelected);
    changeCity(citySelected);
    navigate(citySelected.slug);
  };

  return (
    <div className="cidade">
      <div className="listaCidades">{element}</div>
    </div>
  );
};

export default CitySearch;
