import React from 'react'

import logo from '../../images/itnet_logo.png';

const ImagemLogo = () => {
    return (
      <>
        <div className="logoContainer">
          <img className="logoSite" src={logo} alt="logo_itnet"></img>
        </div>
      </>
    );
}
 
export default ImagemLogo;