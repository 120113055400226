import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Planos from "../../components/Planos";
import PromoITTV from "../../components/PromoITTV";
import { useGlobalContext } from "../../context/GlobalContext";
import smart from "../../Utils/planos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./Canais.css";

const Canais = () => {
  const [expanded, setExpanded] = React.useState("abertos");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { city } = useGlobalContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }),
      100
    );
  }, []);

  React.useEffect(() => {
    if (city === null) {
      navigate("/");
    }
  }, [city]);

  if (!city) {
    return <></>;
  }

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context(
      "../../images/listaCanais/",
      false,
      /\.(png|jpe?g|svg|webp)$/
    )
  );

  const categorias = [
    "Abertos",
    "Esportivos",
    "Religiosos",
    "Notícias",
    "Infantis",
    "Culturais",
    "Filmes e Séries",
    "Variedades",
    "Agrícolas",
  ];

  const renderChannelBlock = (plano, categoria) => {
    if (
      smart[plano].filter((item) => item.categoria === categoria).length === 0
    ) {
      return (
        <div className={"categoriaCanaisContainer " + categoria}>
          <h2 className="categoriaCanaisTitulo">{categoria}</h2>
          <span className="categoriaCanaisDescription">Não incluso</span>
          <span>&nbsp;</span>
        </div>
      );
    }
    return (
      <div className={"categoriaCanaisContainer " + categoria}>
        <h2 className="categoriaCanaisTitulo">{categoria}</h2>
        <div className="categoriasCanaisContent">
          {smart[plano]
            .filter((item) => item.categoria === categoria)
            .map(renderChannelImg)}
        </div>
        <span>&nbsp;</span>
      </div>
    );
  };

  const renderChannelImg = (item) => {
    return (
      <img
        className="imgCanal"
        key={item.nome}
        src={
          images[
            [
              Object.keys(images).filter(
                (imgFilename) =>
                  imgFilename.replace(/\.[^/.]+$/, "") === // remove extension
                  item.nome
                    .normalize("NFD") // remove accents
                    .replace(/[\u0300-\u036f]/g, "") // replace for non-accented
                    .replace(/[^a-zA-Z0-9]/g, "") // remove special chars
                    .toLowerCase()
              ),
            ]
          ]
        }
        alt={item.nome}
      />
    );
  };

  return (
    <>
      <Header offset={-1} />
      <div id="canaisContainer">
        <div id="canaisContent">
          <div id="canaisHeader">
            <h1>Planos</h1>
            <span>Compare os planos e faça a melhor escolha!</span>
          </div>
          <section id="listaCanaisContainer">
            <Accordion
              className="listaCanaisPlano"
              expanded={expanded === "abertos"}
              onChange={handleChange("abertos")}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="listaCanaisTitulo">Smart Abertos</h1>
                <span className="listaCanaisSubtitulo">
                  {city.planos[0].description}
                </span>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexFlow: "row wrap" }}>
                {categorias.map((categoria) =>
                  renderChannelBlock("abertos", categoria)
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="listaCanaisPlano"
              expanded={expanded === "mini"}
              onChange={handleChange("mini")}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="listaCanaisTitulo">Smart Mini</h1>
                <span className="listaCanaisSubtitulo">
                  {city.planos[1].description}
                </span>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexFlow: "row wrap" }}>
                {categorias.map((categoria) =>
                  renderChannelBlock("mini", categoria)
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="listaCanaisPlano"
              expanded={expanded === "plus"}
              onChange={handleChange("plus")}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="listaCanaisTitulo">Smart Plus</h1>
                <span className="listaCanaisSubtitulo">
                  {city.planos[2].description}
                </span>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexFlow: "row wrap" }}>
                {categorias.map((categoria) =>
                  renderChannelBlock("plus", categoria)
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="listaCanaisPlano"
              expanded={expanded === "total"}
              onChange={handleChange("total")}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="listaCanaisTitulo">Smart Total</h1>
                <span className="listaCanaisSubtitulo">
                  {city.planos[3].description}
                </span>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexFlow: "row wrap" }}>
                {categorias.map((categoria) =>
                  renderChannelBlock("total", categoria)
                )}
              </AccordionDetails>
            </Accordion>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Canais;
