import React from 'react';

import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import './FABWhatsapp.css'
import { useGlobalContext } from '../../context/GlobalContext';
const FABWhatsapp = () => {
  const { city } = useGlobalContext();
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100 ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  const handleButtonClick = () => {
    let contactMessage =
      "Olá, vim pelo site Itnet Telecom e gostaria de tirar algumas dúvidas. ";
    let encodedURI = encodeURI(contactMessage);
    window.open(    
      "https://api.whatsapp.com/send?phone=55" +
      city.whatsapp
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "") +
      "&text=" +
      encodedURI, "_blank");
  }
    return (
      <Fab
        style={{position: 'fixed'}}
        className="whatsappButton" //{scrolled ? "whatsappButton barraVisible" : "whatsappButton barraHidden"}
        aria-label="Contrate seu plano via Whatsapp!"
        color='success'
        onClick={handleButtonClick}
      >
        <WhatsAppIcon />
      </Fab>
    );
       
}
 
export default FABWhatsapp;