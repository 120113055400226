
import trezentosmb from "../images/planos/300_mb.png";
import quatrocentosmb from "../images/planos/400_mb.png";
import quinhentosmb from "../images/planos/500_mb.png";
import seiscentosmb from "../images/planos/600_mb.png";
import oitocentosmb from "../images/planos/800_mb.png";
import umgb from "../images/planos/1_gb.png";

import trezentosmbittv from "../images/planos/300_mb_ittv.png";
import quatrocentosmbittv from "../images/planos/400_mb_ittv.png";
import quinhentosmbittv from "../images/planos/500_mb_ittv.png";
import seiscentosmbittv from "../images/planos/600_mb_ittv.png";
import oitocentosmbittv from "../images/planos/800_mb_ittv.png";
import umgbittv from "../images/planos/1_gb_ittv.png";

const featItabaiana = [
  {
    label: "Wi-Fi grátis",
    description: "Acesso a Wi-Fi grátis em pontos selecionados¹",
    icon: 'wifi',
  }

];
const featRiachuelo = [

];
const featSrLima = [

];
const featNsDores = [

];
const featNsGloria = [
  {
    label: "Wi-Fi grátis",
    description: "Acesso a Wi-Fi grátis em pontos selecionados¹",
    icon: 'wifi',
  }

];
const featFeiraNova = [

];
const featRibeiropolis = [

];
const featCumbe = [

];
const featGraccho = [

];

const canaisAbertos = {
  label: "ITTV Canais Abertos",
  description: "Mais de 30 canais abertos disponíveis, com o essencial para sua casa!",
}

const smartMini = {
  label: "ITTV Smart Mini",
  description: "Mais de 50 canais, como ESPN, Cartoon Network e muito mais!",
}

const smartPlus = {
  label: "ITTV Smart Plus",
  description: "Mais de 80 canais, como FOX Sports, Warner TV e muito mais!",
}

const smartTotal = {
  label: "ITTV Smart Total",
  description: "Mais de 100 canais, como Tooncast, History 2 e muito mais!",
}

const planosItabaiana = [
  {
    label: "Itnet Fibra 300 MB",
    bandwidth: 300,
    price: 69,
    image: trezentosmb,
    imageCombo: trezentosmb,
    ittv: canaisAbertos.label,
    description: canaisAbertos.description,
  },
  {
    label: "Itnet Fibra 400 MB",
    bandwidth: 400,
    price: 79,
    image: quatrocentosmb,
    imageCombo: quatrocentosmb,
    ittv: smartMini.label,
    description: smartMini.description,

  },
  {
    label: "Itnet Fibra 500 MB",
    bandwidth: 500,
    price: 89,
    image: quinhentosmb,
    imageCombo: quinhentosmb,
    ittv: smartPlus.label,
    description: smartPlus.description,
  },
  {
    label: "Itnet Fibra 600 MB",
    bandwidth: 600,
    price: 99,
    image: seiscentosmb,
    imageCombo: seiscentosmb,
    ittv: smartTotal.label,
    description: smartTotal.description,
  },
  {
    label: "Itnet Fibra 800 MB",
    bandwidth: 800,
    price: 119,
    image: oitocentosmb,
    imageCombo: oitocentosmb,
    ittv: smartTotal.label,
    description: smartTotal.description,
  },
  {
    label: "Itnet Fibra 1 GB",
    bandwidth: 1000,
    price: 149,
    image: umgb,
    imageCombo: umgb,
    ittv: smartTotal.label,
    description: smartTotal.description,
  },
];

const  planosOutrasCidades = planosItabaiana

// const planosOutrasCidades = [
//   {
//     label: "Itnet Fibra 300 MB",
//     bandwidth: 300,
//     price: 69,
//     image: trezentosmb,
//     imageCombo: trezentosmbittv,
//     description: "Plano itTV Smart Plus",
//   },
//   {
//     label: "Itnet Fibra 400 MB",
//     bandwidth: 400,
//     price: 79,
//     image: quatrocentosmb,
//     imageCombo:  quatrocentosmbittv,
//     description: "Plano itTV Smart Plus",
//   },
//   {
//     label: "Itnet Fibra 500 MB",
//     bandwidth: 500,
//     price: 89,
//     image: quinhentosmb,
//     imageCombo: quinhentosmbittv,
//     description: "Plano itTV Smart Plus",
//   },
//   {
//     label: "Itnet Fibra 600 MB",
//     bandwidth: 600,
//     price: 99,
//     image: seiscentosmb,
//     imageCombo: seiscentosmbittv,
//     description: "Plano itTV Smart Plus",
//   },
//   {
//     label: "Itnet Fibra 800 MB",
//     bandwidth: 800,
//     price: 119,
//     image: oitocentosmb,
//     imageCombo: oitocentosmbittv,
//     description: "Plano itTV Smart Plus",
//   },
//   {
//     label: "Itnet Fibra 1 GB",
//     bandwidth: 1000,
//     price: 149,
//     image: umgb,
//     imageCombo: umgbittv,
//     description: "Plano itTV Smart Plus",
//   },
// ];

export const cidades = {
  itabaiana: {
    value: "Itabaiana",
    slug: "itabaiana",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Av. Ivo de Carvalho, 350 - Centro, Itabaiana - SE 49500-001",
    phone: "0800 431 5555",
    whatsapp: "(79) 99805-0555",
    email: "comercial@itnettelecom.com.br",
    planos: planosItabaiana,
    feat: featItabaiana,
  },
  riachuelo: {
    value: "Riachuelo",
    slug: "riachuelo",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Av. Júlio Vieira de Andrade, 48 - Centro - Riachuelo-SE",
    phone: "",
    whatsapp: "(79) 99943-3555",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featRiachuelo,
  },
  srLima: {
    value: "Santa Rosa de Lima",
    slug: "santa-rosa-de-lima",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Rua da Igreja, 119 - Centro - Santa Rosa de Lima-SE",
    phone: "",
    whatsapp: "(79) 99943-3555",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featSrLima,
  },
  nsDores: {
    value: "Nossa Sr. das Dores",
    slug: "nossa-sr-das-dores",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Rua Gildo Lima Souza, 139 - Centro",
    phone: "0800 494 1555",
    whatsapp: "",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featNsDores,
  },
  nsGloria: {
    value: "Nossa Sr. da Glória",
    slug: "nossa-sr-da-gloria",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Av. Lourival Batista, 184 - Centro",
    phone: "0800 494 1555",
    whatsapp: "",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featNsGloria,
  },
  feiraNova: {
    value: "Feira Nova",
    slug: "feira-nova",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Av. Cônego Miguel Monteiro Barbosa, 364 - Centro",
    phone: "",
    whatsapp: "(79) 99653-2535",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featFeiraNova,
  },
  ribeiropolis: {
    value: "Ribeirópolis",
    slug: "ribeiropolis",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "",
    phone: "",
    whatsapp: "(79) 99805-0555",
    email: "comercial@itnettelecom.com.br",
    planos: planosItabaiana,
    feat: featRibeiropolis,
  },
  cumbe: {
    value: "Cumbe",
    slug: "cumbe",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Praça Profiro Alves Feitosa, 50 - Centro",
    phone: "",
    whatsapp: "(79) 99650-2849",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featCumbe,
  },
  gracho: {
    value: "Graccho Cardoso",
    slug: "graccho-cardoso",
    razaoSocial: "ITNET LTDA - CNPJ 04.690.098/0001-07",
    address: "Av. Getúlio Vargas, 34 - Centro",
    phone: "",
    whatsapp: "(79) 99818-9122",
    email: "comercial@itnettelecom.com.br",
    planos: planosOutrasCidades,
    feat: featGraccho
  },
};
