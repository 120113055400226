import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './NotFound.css'

const NotFound = () => {
    const navigate = useNavigate();
    return (
      <div className="notFoundContainer">
        <Header />
        <div className="notFoundContent">

            <Typography  variant='h3' style={{margin: '15px 0'}}>
                Opa!
            </Typography>
            <Typography style={{margin: '15px 0'}}>
                Esta página não existe!
            </Typography>
            <Button variant='contained' style={{margin: '15px 0'}} onClick={() => navigate('/')} >
                Voltar ao início
            </Button>
          
        </div>
      </div>
    );
}
 
export default NotFound;