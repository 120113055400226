import React from "react";
import Carousel from "nuka-carousel";
import { useMediaQuery } from "react-responsive";
import { useGlobalContext } from "../../context/GlobalContext";

import "./Carousel.css";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../images/banners/", false, /\.(jpe?g|svg|webp)$/)
);

const Caroussel = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { city } = useGlobalContext();

  const handleButtonClick = (event) => {
    switch (event.nativeEvent.path[0].id) {
      case "carouselButtonSlide1":
        console.log("clicou no primeiro botão");
        break;
      case "carouselButtonSlide2":
        console.log("clicou no segundo botão");
        break;
      case "carouselButtonSlide3":
        console.log("clicou no terceiro botão");
        break;
      case "carouselButtonSlide4":
        console.log("clicou no quarto botão");
        break;
      default:
        break;
    }
    console.log(event);
  };

  let slides = []

  Object.entries(images).forEach((img) => {
    const [image, path] = img;
    if (isMobile) {
      if (image.includes("_mobile")) {
        slides.push(
          <div className="slideContainer">
            <img
              className="imgCarousel"
              alt="imagem slideshow"
              src={path}
            />
          </div>
        );
      }
    } else {
      if (!image.includes("_mobile")) {
        slides.push(
          <div className="slideContainer">
            <img
              className="imgCarousel"
              alt="imagem slideshow"
              src={path}
            />
          </div>
        );
      }
    }
  })

  return (
    <div className="carouselContainer">
      <Carousel
        autoplay={true}
        autoplayInterval={3000}
        withoutControls={true}
        dragging={true}
        speed={700}
        wrapAround={true}
      >
        {slides}
      </Carousel>
    </div>
  );
};

export default Caroussel;
