import React from "react";

import CitySearch from "../../components/CitySearch";
import "./Home.css";
import logo from "../../images/itnet_logo.png";

function Home() {
  return (
    <>
      <div className="containerCidades">
        <div className="escolhaCidade">
          <div className="logoContainer">
            <img id="logoItnet" src={logo} alt="logo_itnet"></img>
          </div>
          <div className="spanCidade">
            <span>Escolha sua cidade</span>
          </div>
        </div>
        <CitySearch />
      </div>
    </>
  );
}

export default Home;
