import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FABWhatsapp from '../../components/FABWhatsapp';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { FormControl, TextField, Typography, Button} from '@mui/material';

import './Contato.css'
const Contato = () => {
  const navigate = useNavigate();
  const { city } = useGlobalContext();

  
  
  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);

  const [nome, setNome] = React.useState("");
  const [endereco, setEndereco] = React.useState("");
  const [numero, setNumero] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [mensagem, setMensagem] = React.useState("");

  const handleChange = (event) => {
    switch (event.target.id) {
      case "nomeTextCadastro": 
        setNome(event.target.value);
        break;
      case "enderecoTextCadastro": 
        setEndereco(event.target.value);
        break;
      case "numeroTextCadastro": 
        setNumero(event.target.value);
        break;
      case "telefoneTextCadastro": 
        setTelefone(event.target.value);
        break;
      case "mensagemTextCadastro": 
        setMensagem(event.target.value);
        break;
      default: 
        break;
    }
  };

  const handleButtonClick = (event) => {
    
  }

  React.useEffect(() => {
    if (city === null) {
      navigate("/");
    }
  }, [city]);

  if (!city) {
    return <></>;
  }

  return (
    <div className='cadastroContainer'>
    <div className="formCadastro">
      <FormControl style={{zIndex: '0'}}>
        <div className="textFormCadastro">
          <Typography
            style={{ textAlign: "center", fontWeight: "bold" }}
            variant="h6"
            component="div"
          >
            Contato
          </Typography>
          <Typography
            style={{ textAlign: "center" }}
            variant="body"
            component="div"
          >
            Entraremos em contato com você para tirar suas dúvidas sobre a Itnet!
          </Typography>
        </div>
        <div className="inputFormCadastro">
          <TextField
            sx={{ width: "45ch" }}
            helperText=" "
            id="nomeTextCadastro"
            label="Nome"
            onChange={handleChange}
          />
          <div className="enderecoCadastro">
            <TextField
              sx={{ width: "33ch" , margin: "0 2px" }}
              helperText=" "
              id="enderecoTextCadastro"
              label="Endereço"
              onChange={handleChange}
            />
            <TextField
              sx={{ width: "12ch", margin: "0 2px" }}
              helperText=" "
              id="numeroTextCadastro"
              label="Número"
              onChange={handleChange}
            />
          </div>
          <TextField
            sx={{ width: "45ch"}}
            helperText=" "
            id="cidadeTextCadastro"
            label="Cidade"
            value={city.value+"-SE"}
            disabled
          />
          <TextField
            sx={{ width: "45ch"}}
            helperText=" "
            id="telefoneTextCadastro"
            label="Telefone"
            onChange={handleChange}
          />
          <TextField
            sx={{ width: "45ch" }}
            helperText=" "
            id="telefoneTextCadastro"
            label="Mensagem"
            multiline
            onChange={handleChange}
          />
          <Button variant="contained" sx={{ padding: "15px 20px" }} onClick={handleButtonClick} >
            Solicitar Atendimento
          </Button>
        </div>
      </FormControl>
    </div>
      <Header/>
      <FABWhatsapp/>
      <Footer />
    </div>
  );
}

 
export default Contato;