import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import './Duvidas.css';
import FABWhatsapp from '../../components/FABWhatsapp';

const Duvidas = () => {
    const navigate = useNavigate();
    const { city } = useGlobalContext();
  
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => 
    setExpanded(isExpanded ? panel : false);

    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 10);
    
  
    React.useEffect(() => {
      if (city === null) {
        navigate("/");
      }
    }, [city]);
  
    if (!city) {
      return <></>;
    } 

    return (
      <div className="duvidasContainer">

        <div className="tamanho">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{ width: "53%", flexShrink: 0, fontWeight: "bold" }}
              >
                {expanded === "panel1"
                  ? "- Como proceder em casos de problema com a conexão?"
                  : "+ Como proceder em casos de problema com a conexão?"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Oferecemos Suporte Técnico Online gratuito, disponível todos os
                dias da semana das 8h às 17h, sábados das 8h as 12h. Não
                atendemos aos domingos e feriados.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={{ width: "53%", flexShrink: 0, fontWeight: "bold" }}
              >
                {expanded === "panel2"
                  ? "- Quais as formas de pagamento de mensalidades?"
                  : "+ Quais as formas de pagamento de mensalidades?"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Os pagamentos podem ser feitos através de boleto bancário,
                cartão de crédito e débito em conta.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={{ width: "83%", flexShrink: 0, fontWeight: "bold" }}
              >
                {expanded === "panel3"
                  ? "- Minha internet vai ter velocidade reduzida quando atingir o limite do pacote/plano?"
                  : "+ Minha internet vai ter velocidade reduzida quando atingir o limite do pacote/plano?"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Não. Nossos serviços não funcionam como um pacote de dados.
                Oferecemos uma conexão estável e com uso ilimitado, onde a
                velocidade vai ser constante 24 horas por dia, 7 dias por semana
                e sem limite de download/upload.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                sx={{ width: "53%", flexShrink: 0, fontWeight: "bold" }}
              >
                {expanded === "panel4"
                  ? "- O que posso fazer se minha internet não está funcionando?"
                  : "+ O que posso fazer se minha internet não está funcionando?"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Se ocorrer lentidão, ou alguma outra dificuldade relacionada à
                conexão, desligue o equipamento da energia elétrica por cerca de
                2 minutos. Se o problema persistir, entre em contato com o
                suporte técnico através dos números: (79) 3431-5555 e (79)
                98153-0075. Estamos disponíveis com profissionais capacitados
                para auxiliá-lo. Mantenha suas faturas sempre em dia.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                sx={{ width: "53%", flexShrink: 0, fontWeight: "bold" }}
              >
                {expanded === "panel5"
                  ? "- Existe fibra óptica em todas as ruas?"
                  : "+ Existe fibra óptica em todas as ruas?"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Não. Porém, nossa rede de fibra óptica está em plena expansão.
                Caso tenha interesse neste serviço, preencha o formulário de
                contato e aguarde o contato da nossa equipe comercial.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                sx={{ width: "53%", flexShrink: 0, fontWeight: "bold" }}
              >
                {expanded === "panel6"
                  ? "- Não recebi a fatura desse mês, como faço para pagá-la?"
                  : "+ Não recebi a fatura desse mês, como faço para pagá-la?"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A 2ª via da fatura está disponível na Área do Cliente, baixe
                nosso aplicativo na Play Store ou na Apple Store. Você também
                pode solicitar por e-mail, pelas redes sociais ou retirá-la na
                unidade da sua região. Não esqueça de manter seu cadastro sempre
                atualizado.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <Header/>
        <FABWhatsapp/>
        <Footer/>
      </div>
    );
}
export default Duvidas;