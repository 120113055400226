import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FABWhatsapp from "../../components/FABWhatsapp"
import CardCheckout from './CardCheckout';
import FormCheckout from './FormCheckout';

import './Checkout.css';
import { useGlobalContext } from '../../context/GlobalContext';


const Checkout = () => {

  const location = useLocation();
  const plano = location.state.plano

  const navigate = useNavigate();
  const { city } = useGlobalContext();

  React.useEffect(() => {
    if (city === null) {
      navigate("/");
    }
  }, [city]);

  if (!city) {
    return <></>;
  } 

  return (
    <>
    
      <div className="checkoutContainer">
        <FormCheckout />
        <CardCheckout plano={plano}/>
      </div>
      
      <Footer />
      <Header />
      <FABWhatsapp />
    </>
  );
}
 
export default Checkout;