import React from 'react'
import { useNavigate } from "react-router-dom";
import MenuLateral from './MenuLateral';
import { useGlobalContext } from "../../context/GlobalContext";
import './Header.css';

const HeaderButtons = (props) => {

    const content = ["Planos", "Empresa", "Contato", "Dúvidas", "Teste de Velocidade"] ;

    const navigate = useNavigate();
    const { city } = useGlobalContext();
    
    const handleButtonClick = (value) => {
      if (value === "Área do Assinante") {
        // if(city.value === "Itabaiana" || city.value === "Ribeirópolis")
          window.open("https://central.itnettelecom.com.br/central_assinante_web/login", "_blank")
        // else 
        //   window.open("https://centralitn.itnettelecom.com.br/", "_blank");
        
      
      } else if (value === "Planos") {
        // navigate("/"+city.slug);
        setTimeout(() => window.location.href = "#planosContainer", 300);

      } else if (value === "Teste de Velocidade") {
        window.open("http://ixcsoft.speedtestcustom.com/", "_blank");
      }
      else {
        navigate(`/${value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`);
      }
    }

    return props.isMobile ? (
      <nav className="menuContainer">
        <div className="headerButton buttonOutlined" onClick={e => handleButtonClick(e.target.innerText)}>Área do Assinante</div>
        <MenuLateral content={content} />
      </nav>
    ) : (
      <div className="headerButtonsContainer">
        <div className="headerButton" onClick={e => handleButtonClick(e.target.innerText)}>{content[0]}</div>
        {/*<div className="headerButton" onClick={e => handleButtonClick(e.target.innerText)}>{content[1]}</div>*/}
        {/*<div className="headerButton" onClick={e => handleButtonClick(e.target.innerText)}>{content[2]}</div>*/}
        <div className="headerButton" onClick={e => handleButtonClick(e.target.innerText)}>{content[3]}</div>
        <div className="headerButton" onClick={e => handleButtonClick(e.target.innerText)}>{content[4]}</div>
        <div className="headerButton buttonOutlined" onClick={e => handleButtonClick(e.target.innerText)}>Área do Assinante</div>
      </div>
    );
}
 
export default HeaderButtons;