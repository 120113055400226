import React from "react";

const GlobalContext = React.createContext(null);

export function GlobalProvider({ children }) {
  const [city, setCity] = React.useState();

  React.useEffect(() => {
    const storedCity = JSON.parse(localStorage.getItem("city"));

    setCity(storedCity);
  }, []);

  function changeCity(city) {
    localStorage.setItem("city", JSON.stringify(city));
    setCity(city);
  }

  return (
    <GlobalContext.Provider value={{ city, changeCity }}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  const context = React.useContext(GlobalContext);
  return context;
}
