import React from 'react';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const CardCheckout = (props) => {
  return (
    <div className="cardCheckout">
      <Card className="cardPlano" style={{ maxHeight: "350px"}}>
          <Typography
            style={{ textAlign: "center", fontWeight: "900"}}
            variant="subtitle"
            component="div"
          >
            PLANO ESCOLHIDO
          </Typography>
          <CardMedia
            component="img"
            height="200"
            image={props.plano.image}
            alt="imagem plano"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {props.plano.label}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {props.plano.description}
            </Typography>
            <Typography variant="h6" color="text.secondary">
              {`R$ ${props.plano.price}`.replace(".", ",")}
            </Typography>
          </CardContent>
      </Card>
    </div>
  );
};
 
export default CardCheckout;