import React from "react";
import { useMediaQuery } from "react-responsive";
import Carousel from "nuka-carousel";

import IttvCard from "./IttvCard";

import {
  Card,
  CardActions,
  CardMedia,
  Collapse,
  Typography,
  Button,
  CardContent,
} from "@mui/material";

// import dummyVideo from "../../images/dummyVideo.mp4";
import bannerITTV from "../../images/bannerITTV.png";
import bannerITTVMobile from "../../images/bannerITTVMobile.png";

import canaisSmartMini from "../../images/canais/SmartMini.png";
import canaisSmartPlus from "../../images/canais/SmartPlus.png";
import canaisSmartTotal from "../../images/canais/SmartTotal.png";

// import imgsmartMiniFrente from "../../images/planosIttv/smartMiniFrente.png";
// import imgsmartMiniVerso from "../../images/planosIttv/smartMiniVerso.png";
// import imgsmartPlusFrente from "../../images/planosIttv/smartPlusFrente.png";
// import imgsmartPlusVerso from "../../images/planosIttv/smartPlusVerso.png";
// import imgsmartTotalFrente from "../../images/planosIttv/smartTotalFrente.png";
// import imgsmartTotalVerso from "../../images/planosIttv/smartTotalVerso.png";

import imgDepartDublado from "../../images/filmes/poster_002.png";
import imgAnel from "../../images/filmes/poster_anel004.png";
import imgRecompensa from "../../images/filmes/poster_arecompensa002.png";
import imgCachorro from "../../images/filmes/poster_cachorro002.png";
import imgCarol from "../../images/filmes/poster_carol003.png";
import imgCegonha from "../../images/filmes/poster_cegonha003.png";
import imgMafia from "../../images/filmes/poster_codigomafia.png";
import imgEspadaDeDeus from "../../images/filmes/poster_espadadedeus.png";
import imgEspia from "../../images/filmes/poster_espia003.png";
import imgFamilia from "../../images/filmes/poster_familia003.png";
import imgFuga from "../../images/filmes/poster_fuga_002.png";
import imgInferno from "../../images/filmes/poster_inferno003.png";
import imgInimigos from "../../images/filmes/poster_inimigos003.png";
import imgLacos from "../../images/filmes/poster_lcoscrime.png";
import imgMargin from "../../images/filmes/poster_margin003.png";
import imgLeao from "../../images/filmes/poster_meninaleao002.png";
import imgHonra from "../../images/filmes/poster_missaohonra.png";
import imgRainha from "../../images/filmes/poster_rainha003.png";
import imgResultados from "../../images/filmes/poster_resultados.png";
import imgShakey from "../../images/filmes/poster_shakey003.png";
import imgSyncronicity from "../../images/filmes/poster_syncronicity.png";
import imgVersoes from "../../images/filmes/poster_versoes002.png";

import ChSlider from "./ChSlider";
import { Autoplay, FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "../../Utils/resizeWindow";

//import imgPlanoSergipao from "../../images/planosIttv/planosergipao.webp";
import imgPlanoSmart from "../../images/planosIttv/planosmart.webp";
import imgPlanoSmartMini from "../../images/planosIttv/planomini.webp";
import imgPlanoSmartPlus from "../../images/planosIttv/planoplus.webp";
import imgPlanoSmartTotal from "../../images/planosIttv/planototal.webp";

import "./PromoITTV.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { useNavigate } from "react-router-dom";

const PromoITTV = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { width, height } = useWindowDimensions();


  const filmArtCarousel = [
    imgDepartDublado,
    imgAnel,
    imgRecompensa,
    imgCachorro,
    imgCarol,
    imgCegonha,
    imgMafia,
    imgEspadaDeDeus,
    imgEspia,
    imgFamilia,
    imgFuga,
    imgInferno,
    imgInimigos,
    imgLacos,
    imgMargin,
    imgLeao,
    imgHonra,
    imgRainha,
    imgResultados,
    imgShakey,
    imgSyncronicity,
    imgVersoes,
  ];

  const navigate = useNavigate();

  return (
    <div className="ittvContainer">
      <div className="bannerIttvContainer">
        {!isMobile ? (
          <div id="planosIttvContainer">
            <img className="bannerIttvimg" src={bannerITTV} alt="Banner ITTV" />
            <div className="planoIttvCard">
              <div className="planoIttvCardContent">
              <div className="imgIttvPlano">&nbsp;</div>
                {/* <img
                  className="imgIttvPlano"
                  src={imgPlanoSergipao}
                  alt="Banner Plano Sergipão"
                /> */}
                <img
                  className="imgIttvPlano"
                  src={imgPlanoSmart}
                  alt="Banner Plano Smart"
                />
                <img
                  className="imgIttvPlano"
                  src={imgPlanoSmartMini}
                  alt="Banner Plano Smart Mini"
                />
                <img
                  className="imgIttvPlano"
                  src={imgPlanoSmartPlus}
                  alt="Banner Plano Smart Plus"
                />
                <img
                  className="imgIttvPlano"
                  src={imgPlanoSmartTotal}
                  alt="Banner Plano Smart Total"
                />
              </div>
              <div className="planoIttvButton">
              <div>&nbsp;</div>
              <Button variant="contained" onClick={() => navigate("/canais")}>Veja os canais</Button>
              </div>
            </div>
          </div>
        ) : (
          <div id="planosIttvContainer">
          <img
            className="bannerIttvimg"
            src={bannerITTVMobile}
            alt="Banner ITTV"
          />
          <div className="planoIttvCard">
            <div className="planoIttvCardContent">
              {/* <img
                className="imgIttvPlano"
                src={imgPlanoSergipao}
                alt="Banner Plano Sergipão"
              /> */}
              <img
                className="imgIttvPlano"
                src={imgPlanoSmart}
                alt="Banner Plano Smart"
              />
              <img
                className="imgIttvPlano"
                src={imgPlanoSmartMini}
                alt="Banner Plano Smart Mini"
              />
              <img
                className="imgIttvPlano"
                src={imgPlanoSmartPlus}
                alt="Banner Plano Smart Plus"
              />
              <img
                className="imgIttvPlano"
                src={imgPlanoSmartTotal}
                alt="Banner Plano Smart Total"
              />

            </div>
            <div className="planoIttvButton">
            <Button variant="contained" onClick={() => navigate("/canais")}>Veja os canais</Button>
            </div>
          </div>
        </div>

        )}
      </div>

      <div className="bannerIttvContainer">
        <div className="tituloFilmesContainer">
          <Typography className="tituloFilmes" variant={"h3"}>
            <b>FILMES</b>
          </Typography>
          <Typography className="textoFilmes" variant={"h6"}>
            <b>Tenha acesso a uma ampla biblioteca de filmes, em um só lugar</b>
          </Typography>
        </div>

        <Swiper
          modules={[Autoplay, FreeMode]}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
          }}
          freeMode={{
            sticky: false,
          }}
          speed={9000}
          loop={true}
          loopAdditionalSlides={6}
          slidesPerView={width / (width < 768 ? 170 : 300)}
          id="carouselFilmsContainer"
        >
          {filmArtCarousel.map((item) => (
            <SwiperSlide className="filmesContainer">
              <img
                className="imgFilmesCarousel"
                alt="imagem slideshow"
                src={item}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="tituloFilmesContainer">
          <Typography className="textoFilmes" variant={"h6"}>
            <b>Com novos filmes todos os meses!</b>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PromoITTV;
