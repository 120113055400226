import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Planos from "../../components/Planos";
import PromoITTV from "../../components/PromoITTV";
import { useGlobalContext } from "../../context/GlobalContext";

import "./Funcionario.css";

const Funcionario = () => {
  const { city } = useGlobalContext();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (city === null) {
      navigate("/");
    }
  }, [city]);

  if (!city) {
    return <></>;
  }

  return (
    <>
    <Header offset={-1} />
    <div id="funcionarioContainer">
      <div id="funcionarioContent">
          <h1 id="funcionarioTitle">Área do Funcionário</h1>
          <Planos isFuncionario={true} />
        </div>
        <div id="promoIttvContainer">
        <PromoITTV/>
        <Footer />
        </div>
    </div>
    </>
  );
};

export default Funcionario;
