import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

export default function RateSnackbar() {

  const [state, setState] = React.useState({
    open: false,
    Transition: Slide,
  });

  const handleClick = () => {
    window.location.href =
      "https://www.google.com/search?tbm=lcl&sxsrf=ALiCzsYgNf2EvIblUF-ZIPvzjAxgZd6SHQ:1659124665101&q=Itnet+Telecom&spell=1&sa=X&ved=2ahUKEwiLpbSC8Z75AhVDAtQKHQWUAREQBSgAegQIARBE&biw=1702&bih=915&dpr=1.1#lrd=0x7419eaaaaaaaaab:0x2dd06d61213f8ae5,3,,,&rlfi=hd:;si:3301258790799641317,l,Cg1JdG5ldCBUZWxlY29tSP7i9_mZqoCACFofEAAQARgAGAEiDWl0bmV0IHRlbGVjb20qBggCEAAQAZIBGWludGVybmV0X3NlcnZpY2VfcHJvdmlkZXI;mv:[[-10.688417822680972,-37.426350644889595],[-10.68877777731903,-37.42671695511041]]"
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  React.useEffect(
    () =>
      setTimeout(() => {
        setState({ ...state, open: true });
      }, 1000),
    []
  );

  const action = (
    <>
      <Button
        color="primary"
        size="small"
        onClick={() => handleClick()}
      >
        AVALIAR
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={state.open}
      autoHideDuration={6000}
      onClose={handleClose}
      message="Gosta dos nossos serviços? Avalie a Itnet Telecom no Google! "
      TransitionComponent={state.Transition}
      action={action}
    />
  );
}
