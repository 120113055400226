import React from 'react';
import HeaderButtons from './HeaderButtons';
import ImagemLogo from './ImagemLogo';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import './Header.css';

const Header = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > props.offset ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  }

    return (
        <header className={!scrolled ? "fundoHeader transparent" : "fundoHeader solidheader" }>
          <div className="divLogo" onClick={handleLogoClick}>
            <ImagemLogo />
          </div>
          <div className="divSpacer">&nbsp;</div>
          <HeaderButtons isMobile={isMobile}/>
        </header>
    );
}

Header.defaultProps = {
  offset: 0
};

export default Header;