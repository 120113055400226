import React from 'react';
import { Drawer, Box, List, ListItem, ListItemText, ToggleButton, ToggleButtonGroup, ListItemIcon } from '@mui/material';
import { useNavigate } from "react-router-dom";
import DehazeIcon from '@mui/icons-material/Dehaze';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';

const MenuLateral = (props) => {
    const { city } = useGlobalContext()

    const [drawer, setDrawer] = useState(false);
    let navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
          ) {
            return;
          }
          setDrawer(open);
    }
    const handleItemClick = (value) => {
      if (value === "Área do Assinante") {
        window.location =
          "https://central.itnettelecom.com.br/central_assinante_web/login";
      
      } else if (value === "Planos") {
        navigate(city.slug);
        setTimeout(() => window.location.href = "#planosContainer", 300);

      } 
      else {
        navigate(`/${value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`);
      }
    }

    return (
      <div className="menuLateralContainer">
        <ToggleButtonGroup value={drawer} onChange={toggleDrawer(true)}>
          <ToggleButton value="bold" aria-label="bold">
            <DehazeIcon sx={{ color: "white" }} />
          </ToggleButton>
        </ToggleButtonGroup>
        <Drawer
          anchor={"right"}
          open={drawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          variant={"temporary"}
          PaperProps={{
            sx: { backgroundColor: "#444" },
          }}
        >
          {
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <div>
                  <ListItem
                    button
                    key={0}
                    onClick={(e) => handleItemClick(e.target.innerText)}
                  >
                    <ListItemIcon>
                      <ArrowRightIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary={props.content[0]} />
                  </ListItem>
                  {/*<ListItem
                    button
                    key={1}
                    onClick={(e) => handleItemClick(e.target.innerText)}
                  >
                    <ListItemIcon>
                      <ArrowRightIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary={props.content[1]} />
          </ListItem>
                  <ListItem
                    button
                    key={2}
                    onClick={(e) => handleItemClick(e.target.innerText)}
                  >
                    <ListItemIcon>
                      <ArrowRightIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary={props.content[2]} />
                  </ListItem>*/}
                  <ListItem
                    button
                    key={3}
                    onClick={(e) => handleItemClick(e.target.innerText)}
                  >
                    <ListItemIcon>
                      <ArrowRightIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary={props.content[3]} />
                  </ListItem>
                </div>
              </List>
            </Box>
          }
        </Drawer>
      </div>
    );
}
 
export default MenuLateral;