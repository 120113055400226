import React from 'react'
import { Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'

const cache = {};
function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
  }
  importAll(require.context("../../images/canaisCarousel", false, /\.(png|jpe?g|svg)$/));
  
  const images = Object.entries(cache).map(module => module[1]);

const ChSlider = () => {

    return ( <div>
        <Swiper
        modules={[Autoplay,FreeMode]}
        autoplay={{delay: 1, disableOnInteraction: false, waitForTransition: false}}
        freeMode={{momentum: true, sticky: true}}
        centeredSlides={true}
        loop={true}
        speed={1000}
        spaceBetween={0}
        slidesPerView={8}
        >
            {images.map((item) => (
            <SwiperSlide>
            <img
              src={item}
              alt="Canal"
              style={{width:'50px'}}
            />
            </SwiperSlide>))}
        </Swiper>
    </div> );
}
 

export default ChSlider;